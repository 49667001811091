/* custom.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

/* Body Styles */
body {
    background-color: #0d0d0d;
    color: #e6e6e6;
    font-family: 'Share Tech Mono', monospace;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Press Start 2P', cursive;
    color: #00ffcc;
    text-shadow: 0 0 5px #00ffcc;
}



p, label{
  color: #00ffcc;
}

/* Links */
a {
    color: #ff0066;
    text-decoration: none;
}

a:hover {
    color: #ff66cc;
    text-shadow: 0 0 5px #ff66cc;
}

/* Buttons */
.btn {
    background-color: transparent;
    border: 2px solid #00ffcc;
    color: #00ffcc;
    font-family: 'Press Start 2P', cursive;
    transition: all 0.3s ease;
}

.btn:hover {
    background-color: #00ffcc;
    color: #0d0d0d;
    box-shadow: 0 0 10px #00ffcc;
}

/* Form Controls */
.form-control {
    background-color: #00ffcc2d;
    border: 1px solid #333;
    color: #e6e6e6;
}

.form-control:focus {
    background-color: #00ffcc4b;
    border-color: #00ffcc;
    box-shadow: 0 0 5px #00ffcc;
    color: #eeeeee;
}

/* Labels */
.form-label {
    color: #ff0066;
}

/* Cards */
.card {
    background-color: #1a1a1a;
    border: 1px solid #333;
    box-shadow: 0 0 10px rgba(0, 255, 204, 0.1);
}

.card-title {
    color: #00ffcc;
    text-shadow: 0 0 5px #00ffcc;
}

/* Tables */
.table {
    color: #e6e6e6;
}

.table thead th {
    background-color: #1a1a1a;
    border-bottom: 2px solid #00ffcc;
    color: #00ffcc;
}

.table tbody tr:hover {
    background-color: #262626;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #1a1a1a;
}

/* Alerts */
.alert-info {
    background-color: #1a1a1a;
    border: 1px solid #00ffcc;
    color: #00ffcc;
    box-shadow: 0 0 5px #00ffcc;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #0d0d0d;
}

::-webkit-scrollbar-thumb {
    background: #00ffcc;
}

::-webkit-scrollbar-thumb:hover {
    background: #ff0066;
}

/* Modals */
.modal-content {
    background-color: #1a1a1a;
    border: 1px solid #333;
    color: #e6e6e6;
}

.modal-header, .modal-footer {
    border-color: #333;
}

.modal-title {
    color: #00ffcc;
    text-shadow: 0 0 5px #00ffcc;
}

/* Customize Inputs */
input, select, textarea {
    font-family: 'Share Tech Mono', monospace;
}

/* Hover Effects */
.btn, .form-control, .card, .modal-content {
    transition: all 0.3s ease;
}

/* Background Animation */
body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 50% 50%, rgba(0, 255, 204, 0.1), transparent 70%), url('https://www.transparenttextures.com/patterns/45-degree-fabric-dark.png');
    pointer-events: none;
}

/* Glitch Effect on Headings */
@keyframes glitch {
    0% {
        text-shadow: 2px 2px #ff0066;
    }
    20% {
        text-shadow: -2px -2px #00ffcc;
    }
    40% {
        text-shadow: 2px -2px #ff0066;
    }
    60% {
        text-shadow: -2px 2px #00ffcc;
    }
    80% {
        text-shadow: 2px 2px #ff0066;
    }
    100% {
        text-shadow: -2px -2px #00ffcc;
    }
}

h1, h2, h3 {
    animation: glitch 1s infinite;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    h1, h2, h3 {
        font-size: 1.5rem;
    }
    
    .btn {
        font-size: 0.8rem;
    }
}


.card-body{
    color: #eeeeee;
}